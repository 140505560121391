import type { LineItem, Money, Product, ProductVariant } from "@graphql/generated/components";

export enum EventTypes {
    userData = "user_data",
    cartContents = "cart_contents",
    selectItem = "select_item",
    viewItem = "view_item",
    loadItemsList = "load_item_list",
    viewItemsList = "view_item_list",
    addToCart = "add_to_cart",
    addToWishlist = "add_to_wishlist",
    removeFromWishlist = "remove_from_wishlist",
    removeFromCart = "remove_from_cart",
    viewCart = "view_cart",
    beginCheckout = "begin_checkout",
    addShippingInfo = "add_shipping_info",
    addPaymentInfo = "add_payment_info",
    purchase = "purchase",
    newsletterSubscribe = "newsletter_subscribe",
    listFilterApplied = "list_filter_applied",
    listSortingApplied = "list_sorting_applied",
    purchaseStatus = "purchase_status",
    accountCreate = "account_create",
    viewPromotion = "view_promotion",
    selectPromotion = "select_promotion",
    pageView = "page_view",
    viewItemsFromCartRecommendations = "view_items_from_cart_recommendations",
    selectItemFromCartRecommendations = "select_item_from_cart_recommendations",
    addToCartFromCartRecommendations = "add_to_cart_from_cart_recommendations",
    productAvailability = "product_availability",
}

export enum AlgoliaEventMethods {
    init = "init",
    clickedFilters = "clickedFilters",
    viewedFilters = "viewedFilters",
    clickedObjectIDsAfterSearch = "clickedObjectIDsAfterSearch",
    clickedObjectIDs = "clickedObjectIDs",
    purchasedObjectIDsAfterSearch = "purchasedObjectIDsAfterSearch",
    purchasedObjectIDs = "purchasedObjectIDs",
    convertedObjectIDsAfterSearch = "convertedObjectIDsAfterSearch",
    convertedObjectIDs = "convertedObjectIDs",
    addedToCartObjectIDsAfterSearch = "addedToCartObjectIDsAfterSearch",
    addedToCartObjectIDs = "addedToCartObjectIDs",
    convertedFilters = "convertedFilters",
    viewedObjectIDs = "viewedObjectIDs",
}

export type PossibleEventItem = Product | ProductVariant | LineItem;

export type ListData = {
    listName?: string;
    listId?: string;
    position?: number;
    lastAppliedFilter?: any;
    recommendationCartItemId?: string;
    recommendationCartItemName?: string;
};

export enum AnalyticsListTypes {
    plp = "product_listing_page",
    search = "search",
    recommendations = "recommendations",
    recentlyViewed = "recently_viewed",
    upsellInCart = "upsell_in_cart",
    custom = "custom",
}

export type AnalyticDefaultItem = {
    item_id: string | number; // ObjectID
    item_variant: string; //sku
    item_name: string;
    promotion_name?: string;
    promotion_id?: string;
    item_list_name?: string;
    item_list_id?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_brand: string;
    currency: string;
    price: number;
    quantity: number;
    index: number;
    discount?: number;
    coupon: string;
    list_sorting?: string;
    list_filter_category?: string;
    list_filter_name?: string;
    list_search_key?: string;
    barcode: string;
    recommendation_cart_item_id?: string;
    recommendation_cart_item_name?: string;
};

export type AnalyticsExtendedItem = AnalyticDefaultItem & {
    colour?: string;
    variant_price?: number;
    full_price?: number;
    default_variant?: string;
    size?: string;
    size_imperial?: string;
    size_type?: string;
    barcode?: string;
    stock_count_online?: number;
    stock_count_offline?: number;
    on_sale?: string;
    label_name?: string;
    label_source?: string;
    source?: string;
};

export type AlgoliaItem = {
    id: unknown;
    name: string;
    dominantColor: string;
    gender: string;
    factsAndBenefits: string[];
    price: number;
    priceRange: string;
    collectionClass: string;
    collectionSegment: string;
    hasStock: boolean;
    collectionType: string;
    division: string;
    sizes: string[];
    variants: { size: string; availableQuantity: number; sku: string }[];
    listPrice: number;
    materialGroup: string;
    productColors: { objectID: string }[];
    objectID: string;
    queryID: string;
    index: number;
    productName: string;
    articleNumber: string;
    colorCode: string;
    categories: string[];
    image: string;
    hasDiscount: boolean;
    listPriceRaw: Money;
    priceRaw: Money;
    discountPercentage: number;
    imageBackgroundColor: string;
    badges: string;
    descriptiveHeading: string;
};
