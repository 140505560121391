interface SiteSettingsEntry {
    contentfulAuthorizationPageMicrocopySetKey: string;
    contentfulShoppingBagMicrocopySetKey: string;
    contentfulGlobalMicrocopySetKey: string;
    contentfulAdyenMicrocopySetKey: string;
    contentfulPDPMicrocopySetKey: string;
    contentfulLocaleTagId: string;
    importUser?: boolean;
    isProductInfoRequired?: boolean;
    showPriceLegalText?: boolean;
    isSalesTaxEstimationRequired?: boolean;
}

const siteSettingsDefaultValues: Pick<
    SiteSettingsEntry,
    | "contentfulAuthorizationPageMicrocopySetKey"
    | "contentfulPDPMicrocopySetKey"
    | "contentfulGlobalMicrocopySetKey"
    | "contentfulAdyenMicrocopySetKey"
    | "contentfulShoppingBagMicrocopySetKey"
> = {
    contentfulAuthorizationPageMicrocopySetKey: "pageAuthorization",
    contentfulShoppingBagMicrocopySetKey: "pageShoppingBag",
    contentfulGlobalMicrocopySetKey: "global",
    contentfulAdyenMicrocopySetKey: "adyenPayment",
    contentfulPDPMicrocopySetKey: "pageProductDetailPage",
};

export const siteSettingsConfig = {
    "my.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnMy",
    },
    "sg.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnSg",
    },
    "es.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEsEs",
    },
    "ee.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEtEe",
        importUser: true,
    },
    "in.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnIn",
        isProductInfoRequired: true,
        showPriceLegalText: true,
    },
    "kr.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeKoKr",
        isProductInfoRequired: true,
    },
    "ca.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnCa",
        isSalesTaxEstimationRequired: true,
        hasShippingFees: true,
    },
    "us.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnUs",
        isSalesTaxEstimationRequired: true,
        hasShippingDetails: true,
        supportGlobalPrivacyControl: true,
        hasShippingFees: true,
    },
    "au.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnAu",
        isSalesTaxEstimationRequired: true,
    },
    "jp.ecco.com": {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeJaJp",
    },
};

const currentSiteSettings = siteSettingsConfig[process.env.NEXT_PUBLIC_HOSTNAME];

if (!currentSiteSettings) {
    throw new Error(`No Config found for hostname ${process.env.NEXT_PUBLIC_HOSTNAME}`);
}

export const getSiteSettings = currentSiteSettings ? currentSiteSettings : null;

export const isProduction = process.env.NODE_ENV === "production";
